import Prismic from "prismic-javascript";
import {
  customFetch,
  IErrorResponse,
  ISuccessResponse,
} from "helpers/customFetch";
import { HTTPStatusCode } from "interfaces/interfaces";
import { IFetchBlock } from "./normalize";

const REPOSITORY = process.env.NEXT_PUBLIC_PRISMIC_REPOSITORY_NAME;
const REF_API_URL = `https://${REPOSITORY}.cdn.prismic.io/api/v2`;
const GRAPHQL_API_URL = `https://${REPOSITORY}.cdn.prismic.io/graphql`;

export const PrismicClient = Prismic.client(REF_API_URL, {});

export interface IPreviewData {
  ref: string;
}

export interface IOptions {
  previewData?: IPreviewData;
  variables?: Record<string, unknown>;
}

export async function prismicFetch<TS, TE>(
  graphql: string,
  options: IOptions = {}
): Promise<ISuccessResponse<TS> | IErrorResponse<TE>> {
  const { previewData, variables } = options;

  const url =
    `${GRAPHQL_API_URL}` +
    `?query=${encodeURI(graphql)}` +
    `&variables=${encodeURI(JSON.stringify(variables))}`;

  let ref;
  try {
    ref = (
      typeof previewData?.ref !== "undefined"
        ? previewData.ref
        : (await PrismicClient.getApi()).masterRef.ref
    ) as string;
  } catch (e) {
    const error = e instanceof Error ? e : undefined;
    return {
      url,
      ok: false,
      error,
    };
  }

  return customFetch<TS, TE>(url, {
    headers: { "Prismic-Ref": ref, "Content-Type": "application/json" },
  });
}

export async function fetchPrismicEdge<T>({
  query,
  variables,
  previewData,
  normalize,
}: IFetchBlock): Promise<ISuccessResponse<T> | IErrorResponse<string>> {
  const response = await prismicFetch<Record<string, unknown>, string>(query, {
    previewData,
    variables,
  });

  if (response.ok) {
    // @ts-ignore
    const [key] = Object.keys(response.body.data);
    // @ts-ignore
    const edge = response?.body?.data?.[key]?.edges[0];
    if (!edge) {
      return {
        url: response.url,
        ok: false,
        status: HTTPStatusCode.NOT_FOUND,
      };
    }
    const body = normalize ? await normalize(edge.node) : edge.node;
    const { headers, status, url } = response;
    return { body, ok: true, headers, status, url };
  } else {
    return { ok: false, status: response.status, url: response.url };
  }
}

export async function fetchPrismicEdges<T>({
  query,
  variables,
  previewData,
  normalize,
}: IFetchBlock): Promise<ISuccessResponse<T> | IErrorResponse<string>> {
  const response = await prismicFetch<Record<string, unknown>, string>(query, {
    previewData,
    variables,
  });

  if (response.ok) {
    // @ts-ignore
    const [key] = Object.keys(response.body.data);
    // @ts-ignore
    const edges = response?.body?.data?.[key]?.edges;
    if (!edges) {
      return {
        url: response.url,
        ok: false,
        status: HTTPStatusCode.NOT_FOUND,
      };
    }
    const body = normalize ? await normalize(edges) : edges;
    const { headers, status, url } = response;
    return { body, ok: true, headers, status, url };
  } else {
    return { ok: false, status: response.status, url: response.url };
  }
}
