// With this global namespace we can access _sp_ & __tfcapi without
// typescript complaining.

/* eslint-disable */
import {
  EmptyImageFieldImage,
  FilledImageFieldImage,
  RichTextField,
} from "@prismicio/types";

declare global {
  let _sp_: any;
  let __tcfapi: any;
  namespace NodeJS {
    interface Global {
      _sp_: any;
      __tcfapi: any;
    }
  }
}

/* eslint-enable */

/**
 * @deprecated Use "TPodcastType" in "src/api/podplay/podcast.ts" instead.
 */
export enum PodcastType {
  EPISODIC = "episodic",
  SERIAL = "serial",
}

export enum ImageLoadingType {
  LAZY = "lazy",
  EAGER = "eager",
}

/**
 * @deprecated Use "TPodcast" in "src/api/podplay/podcast.ts" instead.
 */
export interface IPodcast {
  author: string;
  category_id: number[];
  description: string;
  id: number;
  image: string;
  language_iso: string;
  link: string;
  original: boolean;
  popularity: number;
  rss: string;
  seasonal: boolean;
  slug: string;
  title: string;
  type: "episodic" | "serial";
}

export interface IConsent {
  accepted: boolean;
  id: string;
  required: boolean;
  text?: string;
  title?: string;
}

export interface ICategory {
  id: number;
  name: string;
  parent_id: number | null;
}

/**
 * @deprecated Use "TEpisodeType" in "src/api/podplay/episode.ts" instead.
 */
export enum EpisodeType {
  FULL = "full",
  BONUS = "bonus",
  TRAILER = "trailer",
}

/**
 * @deprecated Use "TEpisode" in "src/api/podplay/episode.ts" instead.
 */
export interface IEpisode {
  description: string;
  duration: number | null;
  encoded: string;
  episode: number | null;
  exclusive: boolean;
  id: number;
  mime_type: string | null;
  podcast_id: number;
  published: number;
  season: number | null;
  slug: string;
  title: string;
  type: "full" | "bonus" | "trailer";
  url: string;
}

/**
 * @deprecated Use "TEpisodeProgress" in "src/api/podplay/history.ts" instead.
 */
export interface IEpisodeProgress {
  completed: boolean;
  progress: number;
  duration: number;
  last_listened?: number;
  modified?: number;
  episode_id: number;
}

export enum Language {
  en = "en",
  sv = "sv",
  fi = "fi",
  no = "no",
}

export enum Country {
  "gb" = "gb",
  "se" = "se",
  "fi" = "fi",
  "no" = "no",
}

export enum Locale {
  "en" = "en",
  "en-gb" = "en-gb",
  "sv-se" = "sv-se",
  "fi-fi" = "fi-fi",
  "no-no" = "no-no",
}

export enum PodplayApiErrorCode {
  CATEGORY_NOT_FOUND = 1001,
  PODCAST_NOT_FOUND = 1002,
  EPISODE_NOT_FOUND = 1003,
  LANGUAGE_NOT_FOUND = 1004,
  INVALID_SIGNATURE = 1005,
  SIGNATURE_EXPIRED = 1006,
  INVALID_INPUT = 1007,
  VALIDATING_QUERY_ARGUMENTS_FAILED = 1008,
  USER_ALREADY_EXISTS = 1009,
  USER_NOT_FOUND = 1010,
  USER_PASSWORD_MISMATCH = 1011,
  USER_EMAIL_NOT_VERIFIED = 1012,
  USER_ALREADY_LOGGED_IN = 1013,
  MISSING_AUTH_HEADER = 1014,
  INVALID_TOKEN_FORMAT = 1015,
  JWT_EXPIRED = 1016,
  REFRESH_TOKEN_REVOKED = 1017,
  SIGNATURE_COULD_NOT_BE_LOADED = 1018,
  USER_CLAIMS_ARE_INVALID = 1019,
  ERROR_SENDING_EMAIL = 1020,
  REQUEST_CAME_TOO_FREQUENTLY = 1021,
  INVALID_EMAIL_ADDRESS = 1022,
  PROFILE_NAME_ALREADY_EXISTS = 1023,
  NO_SENDBIRD_USER = 1025,
  NO_SENDBIRD_USER_FOUND = 1027,
}

export interface IPodplayApiErrorBody extends Record<string, unknown> {
  readonly code?: PodplayApiErrorCode | number;
  readonly message?: string;
}

/**
 * @deprecated Use TGender in "src/api/podplay/user" instead.
 */
export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
}

export enum HTTPStatusCode {
  OK = 200,
  NO_CONTENT = 204,
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
}

export interface IImageDimensions {
  width: number;
  height: number;
}

export interface IPrismicImageRetina {
  dimensions: IImageDimensions;
  alt?: string | null;
  url: string;
}

export interface IPrismicImage {
  dimensions: IImageDimensions;
  alt?: string | null;
  url: string;
  retina: IPrismicImageRetina;
}

export enum RootElementId {
  APP = "__next",
  MODAL = "modal-root",
  ALERT = "alert-root",
  MISC = "misc-root",
}

export enum PageType {
  START_PAGE = "start_page",
  HOME_PAGE = "home_page",
  COLLECTIONS_PAGE = "collections_page",
  COLLECTION_PAGE = "collection_page",
  PODCAST_PAGE = "podcast_page",
  USER_PAGE = "user_page",
  EPISODE_PAGE = "episode_page",
  CONTENT_PAGE = "content_page",
}

export enum BlockType {
  FEATURED_PODCAST_BLOCK = "featured_podcast_block",
  PODCAST_COLLECTION_BLOCK = "podcast_collection_block",
  USER_RESUME_EPISODES_BLOCK = "user_resume_episodes_block",
  FEATURE_LIST_BLOCK = "feature_list_block",
  HERO_BLOCK = "hero_block",
  USER_FOLLOWED_PODCAST_BLOCK = "user_followed_podcasts_block",
  PODCAST_TOPLIST_BLOCK = "podcast_toplist_block",
  PODCAST_LIST_BLOCK = "podcast_list_block",
  COLLECTION_PAGE = "collection_page",
  COLLECTION_CLOUD_BLOCK = "collection_cloud_block",
  FOOTER_BLOCK = "footer_block",
  SEARCH_HISTORY_BLOCK = "search_history_block",
  FAQ_BLOCK = "faq_block",
  HEADER_BLOCK = "header_block",
  USER_RECOMMENDED_PODCASTS_BLOCK = "user_recommended_podcasts_block",
}

export interface IUserFollowedPodcast {
  document_name: string | null;
  heading: string;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.USER_FOLLOWED_PODCAST_BLOCK;
  };
}

export interface IUserRecommendedPodcasts {
  document_name: string | null;
  heading: string;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.USER_RECOMMENDED_PODCASTS_BLOCK;
  };
}

export interface ISearchHistory {
  document_name: string | null;
  heading: string;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.SEARCH_HISTORY_BLOCK;
  };
}

export interface IFooter {
  document_name: string | null;
  instagram_url: string;
  facebook_url: string;
  bauermedia_url: string;
  apple_url: string;
  apple_label: string;
  google_url: string;
  google_label: string;
  user_menu_heading: string;
  market_menu_heading: string;
  footerBody: IFooterSlices[];
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.FOOTER_BLOCK;
  };
}

export interface IMenuItem {
  link: AnyLink | null;
  label: string;
  requires_login: boolean;
}

export interface ICollection {
  heading: string;
  description: string;
  image: IPrismicImage | null;
  main_collection: boolean;
  featured_collection: boolean;
  _meta: {
    uid: string;
    type: BlockType;
  };
  child_collections:
    | [
        {
          collection: {
            heading: string;
            _meta: {
              uid: string;
            };
          } | null;
        }
      ]
    | null;
}

export interface IHeaderMainCollection {
  heading: string;
  main_collection: boolean;
  uid: string;
  children:
    | {
        heading: string;
        uid: string;
      }[]
    | null;
}

export interface IHeader {
  document_name: string | null;
  menu: IMenuItem[];
  startpage: string;
  profile_page: string;
  signup_or_login: string;
  search: string;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.PODCAST_COLLECTION_BLOCK;
  };
}

export interface IFeature {
  heading: string | null;
  desktop_image:
    | EmptyImageFieldImage
    | FilledImageFieldImage
    | null
    | undefined;
  mobile_image: EmptyImageFieldImage | FilledImageFieldImage | null | undefined;
  description: string | null;
  button_text: string | null;
  link: IPrismicCollectionPageLink | IPrismicPodcastPageLink | null;
  _meta: {
    id: string;
  };
}
export interface IFeatureList {
  document_name: string | null;
  list: { feature: IFeature }[];
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.FEATURE_LIST_BLOCK;
  };
}

export type AnyBlock =
  | IFeaturedPodcast
  | IFeatureList
  | IHero
  | IFooter
  | IUserFollowedPodcast
  | IPodcastCollection
  | IPodcastToplist
  | IPodcastList
  | ICollectionCloud
  | ICollectionPage
  | ISearchHistory
  | IFaq
  | IHeader
  | IUserRecommendedPodcasts;

export interface ICollectionPage {
  document_name: string | null;
  title?: string;
  description?: string;
  heading?: string;
  pre_heading?: string;
  image: IPrismicImage;
  blocks: AnyBlock[];
  _meta: {
    uid: string;
    lang: Locale;
    id: string;
    type: BlockType;
  };
}

// TODO: This will most likely be renamed when we do a complete typing of the prismic response.
export interface IListableCollectionPage {
  node: {
    heading?: string;
    _meta: {
      uid: string;
      lang: Locale;
      type: BlockType;
    };
  };
}

export interface IPrismicCollectionLink {
  node: {
    heading?: string;
    image: IPrismicImage;
    _meta: {
      uid: string;
    };
  };
}

export interface ICollectionLink {
  title: string;
  imageUrl: string | null;
  slug: string;
  category_id: string | null;
}

export interface ICollectionCloud {
  document_name: string | null;
  heading: string;
  link: AnyLink;
  collections: ICollectionLink[] | null;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.PODCAST_COLLECTION_BLOCK;
  };
}

export interface IPodcastCollection {
  document_name: string | null;
  heading: string;
  name: string;
  podcasts: {
    podcast: {
      id: number;
      title: string;
      author: string;
      image: string;
      popularity: number;
      original: boolean;
    } | null;
  }[];
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.PODCAST_COLLECTION_BLOCK;
  };
}

export interface IFeaturedPodcast {
  document_name: string | null;
  heading: string;
  pre_heading?: string;
  body: string;
  podcast: IPodcast;
  desktop_background_image: IPrismicImage;
  mobile_background_image: IPrismicImage;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.FEATURED_PODCAST_BLOCK;
  };
}

export interface IHero {
  document_name: string | null;
  heading: string;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.HERO_BLOCK;
  };
}

export interface IPrismicContentPageLink {
  slug: string;
  _linkType: LinkType.Document;
  _meta: {
    lang: Locale;
    type: PageType.CONTENT_PAGE;
  };
}

export interface IPrismicPodcastPageLink {
  _linkType: LinkType.Document;
  _meta: {
    uid: string;
    lang: Locale;
    type: PageType.PODCAST_PAGE;
  };
}

export interface IPrismicStartPageLink {
  _linkType: LinkType.Document;
  _meta: {
    lang: Locale;
    type: PageType.START_PAGE;
  };
}

export interface IPrismicHomePageLink {
  _linkType: LinkType.Document;
  _meta: {
    lang: Locale;
    type: PageType.HOME_PAGE;
  };
}

export interface IPrismicCollectionsPageLink {
  _linkType: LinkType.Document;
  _meta: {
    lang: Locale;
    type: PageType.COLLECTIONS_PAGE;
  };
}

export interface IPrismicCollectionPageLink {
  _linkType: LinkType.Document;
  _meta: {
    uid: string;
    lang: Locale;
    type: PageType.COLLECTION_PAGE;
  };
}

export interface IPrismicExternalLink {
  url: string;
  _linkType: LinkType.Web;
}

export type AnyLink =
  | IPrismicPodcastPageLink
  | IPrismicContentPageLink
  | IPrismicStartPageLink
  | IPrismicHomePageLink
  | IPrismicCollectionPageLink
  | IPrismicCollectionsPageLink
  | IPrismicExternalLink;

export enum LinkType {
  Document = "Link.document",
  Web = "Link.web",
}

export interface IFooterMenu {
  primary: {
    heading: string;
  };
  fields: {
    label: string;
    link: AnyLink;
  }[];
}

export type IFooterSlices = IFooterMenu;

export interface IFaq {
  document_name: string | null;
  heading: string;
  questions: {
    question: string;
    answer: RichTextField;
  }[];
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.FOOTER_BLOCK;
  };
}

export interface IPodcastToplist {
  document_name: string | null;
  heading: string;
  podcasts: {
    id: number;
    title: string;
    author: string;
    image: string;
    original: boolean;
    description: string;
    popularity: number;
    language_iso: string;
  }[];
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.PODCAST_TOPLIST_BLOCK;
  };
}

export interface IPodcastList {
  document_name: string | null;
  heading: string;
  podcasts: (IPodcast | null)[];
  language: Language;
  category: {
    id: number;
    name: string;
  };
  limit: number;
  original: boolean;
  _meta: {
    id: string;
    lang: Locale;
    type: BlockType.PODCAST_LIST_BLOCK;
  };
}

export interface IPrismicBlock {
  block: {
    blocks?: IPrismicBlock[] | AnyBlock[];
    _meta: {
      lang: Locale;
      id: string;
      type: BlockType;
    };
  };
}

export enum Seconds {
  ONE_MINUTE = 60,
  TEN_MINUTES = 600,
  ONE_HOUR = 3600,
  ONE_DAY = 86400,
  ONE_WEEK = 604800,
  ONE_MONTH = 2592000,
  ONE_YEAR = ONE_DAY * 365,
}

export enum Milliseconds {
  TWO_MINUTES = 120000,
}
